@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700;800&display=swap);
body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    background-color: #030303;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    font-size: 18px;
    overflow-x: hidden;
}

h2 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 44px;
}

h3 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 300;
}

@media all and (max-width: 500) {
    h2 {
        font-size: 26px;
    }
}

hr {
    border-bottom: 1px solid #222;
    margin: 100px auto;
}

.smallhr {
    border-bottom: 1px solid #333;
    border-top: #040404;
    border-left: none;
    border-right: none;
    margin: 50px auto;
}

a:link,
a:visited,
a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

a:hover {
    opacity: 0.5;
}

::selection {
    color: #030303;
    background: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.Minter {
    padding: 80px 20px 100px;
    margin: 0 auto;
    text-align: left;
}

input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom: 3px solid #fff;
    font-weight: bold;
    padding-bottom: 5px;
    width: 100%;
    line-height: 32px;
    background: #030303;
    text-align: center;
    font-size: 32px;
    max-width: 60%;
    color: #fff;
}

input:focus {
    outline: none;
    border-bottom: rgba(255, 255, 255, 0.3) solid 3px;
    color: #fff;
}

button,
.button {
    background: white;
    color: #030303 !important;
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 50px;
    align-items: center;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    border: 3px solid rgba(0, 0, 0, 0);
    cursor:pointer;
}

button:disabled,
.button:disabled {
    opacity:0.2 !important;
}

.smallbutton {
    background: white;
    color: #030303 !important;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 50px;
    align-items: center;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    border: 3px solid rgba(0, 0, 0, 0);
}

.buttonhover:not(:disabled):hover {
    background: #040404;
    border: 3px solid #fff;
    color: #fff !important;
    cursor: pointer;
}

.textcenter {
    text-align: center;
}

#title {
    padding-top: 32px;
}

#status {
    color: #fff;
}

#logo {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index:10000;
}

#logo img {
    max-width: 200px;
}

.hero {
    position: relative;
    -webkit-animation: dash 3s linear forwards;
            animation: dash 3s linear forwards;
    opacity: 0;
}

.hero2 {
    opacity: 0;
    -webkit-animation: dash2 3s forwards;
            animation: dash2 3s forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

.hero .buttons {
    text-align: center;
    width: 30%;
    height: 100px;
    position: absolute;
    top: calc(50% - 140px);
    left: 35%;
}

.loggedoutinfo {
    margin-top: 60px;
}

@media all and (max-width: 1080px) {
    .hero .buttons {
        width: 60%;
        top: calc(44% - 80px);
        left: 20%;
    }

    .loggedoutinfo {
        margin-top: 60px;
    }
}

@media all and (max-width: 720px) {
    .hero .buttons {
        width: 70%;
        top: calc(40% - 100px);
        left: 15%;
    }
}

#walletButton {
    text-align: right;
    float: right;
    margin-bottom: 50px;
    line-height: 16px;
    font-size: 16px;
    position: fixed;
    top: 15px;
    right: 15px;
    padding: 12px 20px;
    border-radius: 50px;
    color: #030303;
    border: 3px solid white;
    cursor:pointer;
}

#mintButton {
    margin-top: 40px;
    color: #030303;
    background-color: #fff;
    border: 3px solid white;
    font-size: 15px;
    border-radius: 50px;
    padding: 15px 30px;
    transition: all 0.3s ease;
}

#mintButton:hover {
    color: #fff !important;
    background-color: #030303;
    border: #fff solid 3px;
}

.hero {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

#circles {
    margin: auto;
    overflow: visible;
    max-width: 800px;
    display: block;
}

#circles .st0 {
    margin: auto;
    display: block;
    stroke: #fff;
    stroke-width: 90;
    width: 100%;
    height: 100%;
}

@-webkit-keyframes dash {
    to {
        opacity: 1;
    }
}

@keyframes dash {
    to {
        opacity: 1;
    }
}

@-webkit-keyframes dash2 {
    to {
        opacity: 1;
    }
}

@keyframes dash2 {
    to {
        opacity: 1;
    }
}

.container {
    margin: auto;
    max-width: 800px;
    padding: 20px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
}

.errorline {
    height: 32px;
    background: #fff;
    border-bottom: 2px solid #030303;
    width: 100%;
}

.footer {
    text-align: center;
}

.finished {
    text-align: center;
    margin: 250px 0;
}

.loader {
    height: 32px;
    width: 32px;
}
.loader span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
}
.loader span::before,
.loader span::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    border: 2px solid #fff;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: loader-1 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
    animation: loader-1 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}
@-webkit-keyframes loader-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1.5);
        opacity: 0;
    }
}
@keyframes loader-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(0);
                transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1.5);
                transform: translate3d(0, 0, 0) scale(1.5);
        opacity: 0;
    }
}
.loader-6 span::after {
    -webkit-animation: loader-2 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s
        infinite;
    animation: loader-2 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s infinite;
}
@-webkit-keyframes loader-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1);
        opacity: 0;
    }
}
@keyframes loader-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(0);
                transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1);
                transform: translate3d(0, 0, 0) scale(1);
        opacity: 0;
    }
}

.fadeIn {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */

@media all and (max-width: 500) {
    .center {
        display: block;
        margin: 30px auto;
        position: relative;
    }
}

.center span {
    left: calc(50% - 24px);
}

.moveTop.center {
    padding-top: 10px;
    padding-bottom: 200px;
    position: relative;
    width: 100%;
}

.moveTop.center span {
    left: 0;
    width: 100%;
    margin: 30px auto;
}


.wrapcountdown {
    padding-top: 47%;
    min-height: 600px;
    text-align: center;
}

.countdown {
    text-align: center;
    font-size: 44px;
    line-height: 56px;
    font-weight: bold;
}

#scountdown {
    height: 150px;
    opacity: 0;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.wrapashcollection {
    padding: 15px;
    text-align: center;
}

.p50 {
    max-width: calc(50% - 60px);
    max-height: 300px;
    display: inline-block;
    padding: 30px;
}

.p80 {
    max-width: calc(80%);
    padding: 30px;
    margin: auto;
    display: block;
}

@media all and (max-width: 640px) {
    .p80 {
        max-width: calc(100%);
        padding: 0;
    }

    .p50 {
        max-width: calc(100% - 60px);
        max-height: 300px;
        display: inline-block;
        padding: 30px;
        margin-bottom: 15px;
    }
}

.ashcollection {
    max-width: 100%;
    max-height: 300px;
    border-radius: 300px;
    overflow: hidden;
    -webkit-animation: opacity 0.3s;
            animation: opacity 0.3s;
}

.smallfont {
    font-size: 14px;
    line-height: 18px;
}

.clearfix {
    clear: both;
}

/* SHARE A SPHERE */

.shareSpheres {
    max-width: 1050px;
    margin: auto;
    text-align: center;
    margin-top: 100px;
}

.wrapASphere {
    width: calc(33% - 30px);
    padding-bottom: calc(33% - 30px);
    margin: 15px;
    font-size: 13px;
    text-align: center;
    float: left;
    background-size: contain !important;
    position: relative;
    transition: opacity 0.3s ease-in-out;
}

.wrapASpheresmall {
    width: calc(10% - 30px);
    padding-bottom: calc(10% - 30px);
    margin: 15px;
    font-size: 13px;
    text-align: center;
    float: left;
    background-size: contain !important;
    position: relative;
    transition: opacity 0.3s ease-in-out;
}

@media all and (max-width: 1200px) {
    .wrapASpheresmall{
        width: calc(20% - 30px);
        padding-bottom: calc(20% - 30px);
    }
}

@media all and (max-width: 960px) {
    .wrapASphere {
        width: calc(50% - 30px);
        padding-bottom: calc(50% - 30px);
        margin: 15px;
        font-size: 13px;
        text-align: center;
        float: left;
        background-size: contain !important;
        position: relative;
        transition: opacity 0.3s ease-in-out;
    }

    .wrapASpheresmall{
        width: calc(25% - 30px);
        padding-bottom: calc(25% - 30px);
    }
}

.wrapASphere .tokenid, .wrapASpheresmall .tokenid{
    background: #000;
    font-size: 32px;
    font-weight: bold;
    position: absolute;
    width: 60%;
    max-width: 60%;
    top: calc(50% - 20px);
    left: 20%;
    z-index: 3;
    color: rgba(255, 255, 255, 0.2);
    border-radius:15px;
}

.wrapASphere .shareableleft {
    position: absolute;
    width: 50%;
    max-width: 50%;
    top: calc(50% - 10px);
    left: 2%;
    z-index: 4;
}

.wrapASphere .shareableright {
    position: absolute;
    width: 50%;
    max-width: 50%;
    top: calc(50% - 10px);
    right: 2%;
    z-index: 4;
}

.wrapASphere .title {
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    text-align: center;
}

.wrapASphere .tokenid.visible, .wrapASpheresmall .tokenid.visible {
    color: #fff;
}

.wrapASphere:hover .title {
    display: block;
}

.wrapASphere.sharehover:hover .tokenid {
    display: none;
    background: rgba(0, 0, 0, 0);
}

.wrapASphere.sharehover:hover {
    background: url(/static/media/sharesphere.5a6b6ac8.jpg) !important;
    background-size: contain !important;
}

.wrapASphere.sharehover .sharebutton {
    display: none;
}
.wrapASphere.sharehover:hover .sharebutton {
    display: block;
}

.wrapASphere.sharehover .sharebutton b {
    font-size: 22px;
}

.wrapASphere.sharehover .sharebutton b:hover {
    cursor: pointer;
    opacity: 0.7;
}

.left{
    float:left;
}

.right{
    float:right;
}

.centertext{
    text-align:center;
}

.loginerror{
    text-align:right;
    position: fixed;
    top: 80px;
    right: 30px;
    z-index:100;
    padding:15px;
    background: #570000;
    border-radius: 5px;
}

/* LEADERBOARD */
.leaderboardline{
    font-size:24px;
    font-weight:bold;
    height:30px;
    border-bottom:1px solid #333;
    padding-bottom:15px;
    margin-bottom:15px;
    clear:both;
}

.App-header {
    background-color: #030303;
    font-family: "Open Sans", sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #000;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

